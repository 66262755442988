<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small" label-width="100px">
			<el-form-item class="el_form_item" label="支付编号">
				<el-input class="el_input" v-model="form.payed_num" placeholder="支付编号搜索" clearable></el-input>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="支付流水">
				<el-input class="el_input" v-model="form.this_pay_shipper_ra_num" placeholder="支付流水编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="撤销流水">
				<el-input class="el_input" v-model="form.this_refund_shipper_ra_num" placeholder="撤销流水编号搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="运单编号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="子账户手机">
				<el-input class="el_input" v-model="form.sub_user_tel" placeholder="子账户手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="子账户姓名">
				<el-input class="el_input" v-model="form.sub_user_name" placeholder="子账户姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单子户手机">
				<el-input class="el_input" v-model="form.tord_sub_user_tel" placeholder="运单子户手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="运单子户姓名">
				<el-input class="el_input" v-model="form.tord_sub_user_name" placeholder="运单子户姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人手机">
				<el-input class="el_input" v-model="form.truck_owner_tel" placeholder="收款人手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="收款人姓名">
				<el-input class="el_input" v-model="form.truck_owner_name" placeholder="收款人姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机手机">
				<el-input class="el_input" v-model="form.driver_tel" placeholder="司机手机号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="司机姓名">
				<el-input class="el_input" v-model="form.driver_name" placeholder="司机姓名搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="车牌号">
				<el-input class="el_input" v-model="form.truck_plate_num" placeholder="车牌号搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付类型1">
				<el-select class="el_input" v-model="form.this_pay_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="现金" value="1"></el-option>
					<el-option label="燃油费" value="2"></el-option>
					<el-option label="过路费" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付类型2">
				<el-select class="el_input" v-model="form.pay_mark" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="预付款" value="预付款"></el-option>
					<el-option label="到货付" value="到货付"></el-option>
					<el-option label="回单付" value="回单付"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付状态">
				<el-select class="el_input" v-model="form.pay_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未支付" value="1"></el-option>
					<el-option label="待审核" value="2"></el-option>
					<el-option label="支付完成" value="3"></el-option>
					<el-option label="失败/打回" value="4"></el-option>
					<el-option label="已撤销" value="5"></el-option>
				</el-select>
			</el-form-item>
			<!-- <el-form-item class="el_form_item" label="开票状态">
				<el-select class="el_input" v-model="form.invoice_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="申请中" value="2"></el-option>
					<el-option label="已开出" value="3"></el-option>
					<el-option label="已驳回" value="4"></el-option>
				</el-select>
			</el-form-item> -->
			<!-- <el-form-item class="el_form_item" label="结算状态">
				<el-select class="el_input" v-model="form.settle_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未申请" value="1"></el-option>
					<el-option label="待打款" value="2"></el-option>
					<el-option label="已打款" value="3"></el-option>
					<el-option label="结算失败" value="4"></el-option>
				</el-select>
			</el-form-item> -->
		   <!-- <el-form-item class="el_form_item" label="运费设置">
				<el-select class="el_input" v-model="form.is_set_freight" clearable>
					<el-option label="不限" value="1"></el-option>
					<el-option label="已设置" value="2"></el-option>
					<el-option label="未设置" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" placeholder="备注搜索" clearable></el-input>
			</el-form-item> -->
			<el-form-item class="el_form_item" label="绑卡状态">
				<el-select class="el_input" v-model="form.bind_bankcard_status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未绑卡" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" :label="title" >
				<el-autocomplete
				style="width:175px"
				class="inline-input"
				v-model="form.bl_name"
				:fetch-suggestions="querySearch"
				placeholder="请先输入线路名称"
				@focus="bl_focus"
				></el-autocomplete>
			</el-form-item>
			<el-form-item class="el_form_item">

			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="请款时间">
				<div class="block">
				<el-date-picker
					v-model="date_value"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
            </el-form-item>
			<el-form-item class="el_form_item">

			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" style="background-color:#795A25;border: none;" @click="page_ser">查询</el-button>
				<el-button type="primary" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" style="background-color:#FCF8ED;border: 1px solid #795A25;color:#795A25;" @click="pay_whole_excle">全部导出</el-button>
			</el-form-item>
		</el-form>
		<div class="total">
			<span v-if="total==0">共:{{list.total}}笔</span>
			<span v-if="total==0">总运费:{{list.total_freight_checking}}元</span>
			<span v-if="total==0">给车队长:{{list.total_freight_checking_truck_owner}}元</span>
			<span v-if="total==0">给司机:{{list.total_freight_checking_driver}}元</span>
			<span v-if="total==0">总服务费:{{list.total_service_checking}}元</span>
			<span v-if="total==0">总金额:{{list.total_money}}元</span>
			<span >总吨数:{{list.total_cargo_weight}}吨</span>
			<span v-if="total_num>0">勾选总笔数:{{total_num}}</span>
			<span v-if="total>0">勾选总运费:{{total}}元</span>
			<span v-if="total_service>0">勾选总服务费:{{total_service}}元</span>
			<span v-if="total_invoiced>0">勾选总金额:{{total_invoiced}}元</span>
			<span v-if="total_cargo_weight>0">勾选总吨数:{{total_cargo_weight}}吨</span>
		</div>
		<!-- 表格 -->
		<div class="table">
			<div class="thead">
				<el-checkbox style="margin:10px 13px" @change="payed_all_choose"></el-checkbox>
				<el-button-group style="margin: -4.5px 0 0 0px;">
					<!-- <el-button size="mini" type="success" @click="tords_pay_check_pass('批量通过审核')">批量通过审核</el-button> -->
					<el-button size="mini" type="primary" style="margin-right:5px" @click="tords_pay_check_pass2">批量支付</el-button> <!--会扣款-->
					<el-button size="mini" type="danger" @click="tords_pay_check_revoke('批量撤销支付')">批量撤销请款</el-button>
					<!-- <el-button size="mini" type="success" @click="all_check_pass">全部通过审核</el-button> -->
					<!-- <el-button size="mini" type="danger" @click="all_check_revoke">全部撤销支付</el-button> -->
				</el-button-group>
				<el-button-group style="margin: -4.5px 0 0 15px;">
					<!-- <el-button size="mini" type="success" @click="tords_invoice_apply">批量申请开票</el-button> -->
					<!-- <el-button size="mini" type="success" @click="all_invoice_apply">全部申请开票</el-button> -->
				</el-button-group>
			</div>
			<div class="tbody">
				<div class="tr" v-for="(tord_item,index) in list.data" :key="index">
					<div class="tord_info">
						<div class="item" style="width:180px">
							<div class="top">运单:{{tord_item.truck_tord_num}}</div>
							<!-- <div class="bottom">下单:{{tord_item.creat_time_text}}</div> -->
						</div>
						<div class="item" style="width:300px;flex;1">
							<div class="top">发货地:{{tord_item.case_prov}}/{{tord_item.case_city}}/{{tord_item.case_county}}</div>
							<div class="bottom">{{tord_item.case_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="width:300px;flex;1">
							<div class="top">到货地:{{tord_item.aim_prov}}/{{tord_item.aim_city}}/{{tord_item.aim_county}}</div>
							<div class="bottom">{{tord_item.aim_other_obj.addr_info}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">货物:{{tord_item.cargo_type}}/{{tord_item.cargo_name}}</div>
							<div class="bottom">{{tord_item.cargo_weight}}吨/{{tord_item.cargo_volume}}方</div>
						</div>
						<div class="item" style="width:180px">
							<div class="top" >收款人:{{tord_item.truck_owner_info.name}}/{{tord_item.truck_owner_info.tel}}</div>
							<div class="bottom" style="margin-top: 5px;">司机:{{tord_item.driver_info.name}}/{{tord_item.driver_info.tel}}</div>
							<div v-if="tord_item.sub_user_info.name" style="margin-top: 5px;">子账户:{{tord_item.sub_user_info.name}}/{{tord_item.sub_user_info.tel}}</div>
							<div v-else style="margin-top: 5px;">子账户:无</div>
						</div>
						<div class="item" style="width:100px">
							<div class="top">车辆:{{tord_item.truck_plate_num}}</div>
						</div>
						<div class="item" style="width:130px">
							<div class="top">总运费:{{tord_item.cost_info.freight_total}}元</div>
							<!-- <div class="bottom">已支付:{{tord_item.cost_info.payed_total_of_all}}元</div> -->
						</div>
						<div class="item" style="width:210px">
							<div class="top">提交时间:{{tord_item.pay_time_text}}</div>
						</div>
					</div>
					<div class="payed_list">
						<div class="ptr" v-for="(payed_item,payed_index) in tord_item.payed_list" :key="payed_index">
							<div class="ptd">
								<el-checkbox @change='single_choice(payed_num_choosed[payed_item.payed_num].checked,payed_item)' v-model="payed_num_choosed[payed_item.payed_num].checked"></el-checkbox>
							</div>
							<div class="ptd" style="width:200px">
								<div>{{payed_item.payed_num}}</div>
								<!-- <div>{{payed_item.creat_time_text}}</div> -->
							</div>
							<div class="ptd" style="width:240px" v-if="payed_item.calc_mode==1">
								<div v-if="payed_item.this_settled_of_truck_owner !=0">
									<div>[收款人收款]:{{payed_item.this_settled_of_truck_owner}}元</div>
									<div>{{payed_item.truck_owner_bank_list.num==0?'收款人信息为空':(payed_item.truck_owner_bank_list.num!=1?"收款人绑定多张卡":'') }}</div>
									<div v-if="payed_item.truck_owner_bank_list.num==1">
										<div >收款人:{{payed_item.truck_owner_bank_list_info.card_user_name}}</div>
										<div >收款银行:{{payed_item.truck_owner_bank_list_info.bank_cname}}</div>
										<div >收款卡号:{{payed_item.truck_owner_bank_list_info.card_num}}</div>
									</div>
										<el-button class="btn_left" @click="binding_bankcard(payed_item,'truck_owner')" size="mini" type="text">收款人绑卡</el-button>
								</div>
								<!-- <div>状态:{{payed_item.settle_status_of_truck_owner_text}}</div> -->
							</div>
							<div class="ptd" style="width:280px;" v-if="payed_item.calc_mode==1 ">
								<div v-if="payed_item.this_settled_of_driver !=0">
								<div>[司机收款]:{{payed_item.this_settled_of_driver}}元</div>
								<div>{{payed_item.driver_bank_list.num==0?'收款人信息为空':(payed_item.driver_bank_list.num!=1?"收款人绑定多张卡":'') }}</div>
									<div  v-if="payed_item.driver_bank_list.num==1">
									   	<div>收款人:{{payed_item.driver_bank_list_info.card_user_name}}</div>
										<div>收款银行:{{payed_item.driver_bank_list_info.bank_cname}}</div>
										<div>收款卡号:{{payed_item.driver_bank_list_info.card_num}}</div>
								    </div>
									<el-button class="btn_left" @click="binding_bankcard(payed_item,'driver')" size="mini" type="text">司机绑卡</el-button>
								<!-- <div>状态:{{payed_item.settle_status_of_driver_text}}</div> -->
								</div>
							</div>
							<div class="ptd" style="width:220px;">
								<div>总支付:{{payed_item.this_payed_text}}</div>
								<!-- <div>状态:{{payed_item.pay_status_text}}</div> -->
							</div>
							<div class="ptd" style="width:150px;">
								<div>[服务费]:{{payed_item.service_charge_text}}</div>
								<!-- <div>状态:{{payed_item.pay_status_text}}</div> -->
							</div>
							
							<div class="ptd" style="width:100px;font-size:14px;">
								<div>{{payed_item.pay_mark}}</div>
							</div>
							<div class="ptd" style="width:130px;font-size:12px;">
								<div>支付类型：{{payed_item.this_pay_type_text}}</div>
							</div>
							<div class="ptd" style="display:flex;margin-left:auto;font-size:18px">
								<div style="">
									<el-button v-if="payed_item.pay_status==2" class="btn_left" @click="tord_pay_check_pass(payed_item)" size="mini" type="text">支付</el-button>
									<!-- <el-button v-if="payed_item.pay_status==2" class="btn_left" @click="tord_pay_check_pass(payed_item)" size="mini" type="text">通过</el-button> -->
									<el-button v-if="payed_item.pay_status==2" class="btn_left" @click="pay_check_revoke_plan(payed_item)" size="mini" type="text" style="color:red;margin-left:10px">撤销</el-button>
									<!-- <el-button v-if="payed_item.pay_status==2" class="btn_left" @click="pay_check_revoke(payed_item)" size="mini" type="text" style="color:red">驳回支付</el-button> -->
									<!-- <el-button v-if="payed_item.pay_status==3" class="btn_left" @click="pay_check_refuse(payed_item)" size="mini" type="text" style="color:red">撤销支付</el-button> -->
								</div>
									
								<div style="margin-left:15px" v-if="payed_item.pay_status==3" >
									<el-button v-if="payed_item.invoice_status==1" class="btn_left" @click="invoice_apply(payed_item)" size="mini" type="text">申请开票</el-button>
									<el-button v-if="payed_item.invoice_status==2" class="btn_left" @click="invoice_record(payed_item)" size="mini" type="text">开票记录</el-button>
									<el-button v-if="payed_item.invoice_status==3" class="btn_left" @click="invoice_record(payed_item)" size="mini" type="text">开票记录</el-button>
									<el-button v-if="payed_item.invoice_status==4" class="btn_left" @click="invoice_record(payed_item)" size="mini" type="text">开票记录</el-button>
									<el-button v-if="payed_item.invoice_status==4" class="btn_left" @click="invoice_apply(payed_item)" size="mini" type="text">申请开票</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 分页 -->
		<el-pagination
			class="margin-top-xl"
			@size-change="page_size_change"
			@current-change="page_current_change"
			:current-page="page.p"
			:page-sizes="[10, 50, 200, 500]"
			:page-size="page.num"
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper"
		></el-pagination>

		<!-- 重置支付密码 -->
		<payCheckPass
			:is_show="pay_check_pass.is_show"
			:need_pass_list="pay_check_pass.need_pass_list"
			@passed="pay_check_passed"
		></payCheckPass>

		<!-- 导出 -->
		<serRelExport
			:is_show="tord_export.is_show"
			:rull="tord_export.rull"
			@close="tord_export_close"
			:pay_excle_liet="tord_export.data"
			:type="tord_export.type"
			:ctr="tord_export.ctr"
			:mod="tord_export.mod"
			:pay_status="tord_export.pay_status"
		></serRelExport>

		<!-- 绑定银行卡 -->
		<bindingBankCard
			:is_show="bank_card.is_show"
			:user_num="bank_card.user_num"
			@success="get_page_data"
		></bindingBankCard>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import payCheckPass from './tord_pay_check_pass.vue'
	import serRelExport from './tord_pay_excle.vue'
	import bindingBankCard from './binding_bank_card.vue'
	export default {
		components:{
			payCheckPass,
			serRelExport,
			bindingBankCard
		},
		data() {
			return {

				title:"",

				//搜索条件
				form: {
					payed_num:'',//货源编号
					this_pay_shipper_ra_num:'',//支付流水编号
					this_refund_shipper_ra_num:'',//撤销流水编号
					truck_tord_num:'',//货源编号
					sub_user_tel:'',//子用户手机号
					sub_user_name:'',//子用户姓名
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给收款人的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					mark:'',//备注
					truck_plate_num:'',//车牌号
					is_set_freight:'',//运费设置
					creat_time_start:'',
					creat_time_end:'',
					bl_name:'',//线路名称
					pay_mark:'',//支付类型二
					bind_bankcard_status:'',//绑卡状态
					cargo_name:"",//货物名称
					tord_sub_user_name:'',//运单子户姓名
					tord_sub_user_tel:'',//运单子户手机号
				},
				total_num:0,
				total:0,
				total_service:0,
				total_invoiced:0,
				total_cargo_weight:0,//勾选吨数
				bl_list:[],//线路列表
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
					total_freight_checking:'',//总运费
					total_service_checking:'',//总服务费
					total_money:'',//总金额
					total_cargo_weight:'',//总吨数
					total_freight_checking_truck_owner:'',//给车队长
					total_freight_checking_driver:'',//给司机
				},
				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//支付确认界面
				pay_confirm:{
					confirm_list:[],
					is_show:0,
				},

				//多选
				payed_num_choosed:{},

				//支付审核通过
				pay_check_pass:{
					is_show:0,
					need_pass_list:[],
				},
				date_value:'',
				//导出数据
				tord_export:{
					is_show:0,
					rull:{},
					data:[],
					type:0,//导出类型 1全选 2勾选
					ctr:'tord_payed_list_by_shipper',
					mod:'truck_tord_real_time',
					pay_status:2,
				},
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//银行卡绑定
				bank_card:{
					is_show:0,
					user_num:'',
				}
			}
		},
		computed:{
			...mapState(['shipper_set','user_info'])
		},
		created() {

			//取出参数
			let query=this.$route.query;
			for(var i in query){
				this.form[i]=query[i]
			}

			//读取货源
			this.get_page_data()
		},
		mounted(){
			if(["18039266325","18621627027"].indexOf(this.user_info.tel)>-1){
				this.title = '项目搜索'
			}else{
				this.title = '线路搜索'
			}
		},
		methods: {
			//银行卡绑定
			binding_bankcard(data,type){
				this.bank_card.is_show ++
				if(type=='driver'){
					this.bank_card.user_num = data.driver_num
				}else{
					this.bank_card.user_num = data.truck_owner_num
				}
				
			},
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},
			//导出
			pay_whole_excle(){
				var pay_list = this.get_payed_choosed()
				if(pay_list.length == 0){
					//未勾选数据
					this.$my.other.confirm({
						content:"当前未勾选数据,是否全部导出",
						confirm:()=>{
							this.tord_export.type =1
							//置入条件
							this.tord_export.rull=this.form

							//打开界面
							this.tord_export.is_show++
						}
					});
				}else{
					this.tord_export.type =2
					var pay_excle_liet = []
					//有勾选
					pay_list.forEach(item=>{
						if(item.exlce_pay.this_settled_of_truck_owner == 0.00){
							item.exlce_pay.settle_status_of_truck_owner_text = ''
						}
						if(item.exlce_pay.this_settled_of_driver ==0){
							item.exlce_pay.settle_status_of_driver_text =''
						}
						if(item.exlce_pay.driver_bank_list.num ==1){
							item.exlce_pay.driver_bank_name = item.exlce_pay.driver_bank_list.list[0].card_user_name
							item.exlce_pay.driver_bank_tel = item.exlce_pay.driver_bank_list.list[0].card_user_tel
							item.exlce_pay.driver_bank_cname = item.exlce_pay.driver_bank_list.list[0].bank_cname
						}else{
							item.exlce_pay.driver_bank_name = ''
							item.exlce_pay.driver_bank_tel = ''
							item.exlce_pay.driver_bank_cname = ''
						}
						if(item.exlce_pay.truck_owner_bank_list.num ==1){
							item.exlce_pay.truck_owner_bank_name = item.exlce_pay.truck_owner_bank_list.list[0].card_user_name
							item.exlce_pay.truck_owner_bank_tel = item.exlce_pay.truck_owner_bank_list.list[0].card_user_tel
							item.exlce_pay.truck_owner_bank_cname = item.exlce_pay.truck_owner_bank_list.list[0].bank_cname
						}else{
							item.exlce_pay.truck_owner_bank_name = ''
							item.exlce_pay.truck_owner_bank_tel = ''
							item.exlce_pay.truck_owner_bank_cname = ''
						}
						pay_excle_liet.push(item.exlce_pay)
					})
					this.tord_export.data =pay_excle_liet

					//打开界面
					this.tord_export.is_show++
				}
			},
			//线路查询
			bl_focus(){
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_shipper'
					},
					callback:(data)=>{
						var bl_list = []
						//遍历
						for(var item of data.list){

							//结算方式
							var settlement_type_text=""
							switch(item.is_need_return){
								case '1':settlement_type_text="按吨数结算";break;
								case '2':settlement_type_text="按方数结算";break;
								case '3':settlement_type_text="按趟结算";break;
							}

							//是否是双边
							var is_need_return_text=""
							switch(item.is_need_return){
								case '1':is_need_return_text="双边";break;
								case '2':is_need_return_text="单边";break;
							}

							//装货地其他数据
							item.case_other_obj=JSON.parse(item.case_other);

							//卸货地其他数据
							item.aim_other_obj=JSON.parse(item.aim_other);

							//汇集路线数据
							item.info_text=item.case_prov+'/'+item.case_city+'/'+item.case_county+' ---- '+item.aim_prov+'/'+item.aim_city+'/'+item.aim_county+' ('+is_need_return_text+') ('+settlement_type_text+')'+'('+item.bl_name+')'

							bl_list.push({
								value:item.bl_name,
								address:item.bl_name
							})
						}

						//置入路线数据
						this.bl_list=bl_list
					}
				})
			},
		   querySearch(queryString, cb) {
				var restaurants = this.bl_list;
				var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
				// 调用 callback 返回建议列表的数据
				cb(results);
			},
			createFilter(queryString) {
				return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
				};
			},
			// 单选change事件
			single_choice(){
				this.total = 0
				this.total_service=0
				this.total_invoiced=0
				this.total_cargo_weight = 0
				var total_invoiced=0
				var total_service=0
				var total = 0
				var total_cargo_weight = 0
				var item =  this.get_payed_choosed()
				this.total_num=0;
				for(let i in item){
					// total_invoiced += Number(this.invoice_num_choosed[item])
					total_invoiced += Number(item[i].total_invoiced)
					total_service += Number(item[i].total_service)
					total_cargo_weight+=Number(item[i].cargo_weight)
					total+=item[i].total
					this.total = total.toFixed(2)
					this.total_service = total_service.toFixed(2)
					this.total_invoiced =total_invoiced.toFixed(2)
					this.total_cargo_weight =total_cargo_weight.toFixed(2)
					this.total_num++;
				}
			},
			//多选
			payed_all_choose(event){//全选
				if(event){
					for(var payed_num in this.payed_num_choosed){
						this.payed_num_choosed[payed_num].checked=true;
					}
				    var arr = this.list.data
					var total = 0
					var total_service=0
					var total_invoiced=0
					var total_cargo_weight = 0
					this.total_num=0;
						for(let i in arr){
							var payed_list = arr[i].payed_list
							for(let i in payed_list){
								total+=Number(payed_list[i].this_settled)
								total_service+=Number(payed_list[i].this_payed-payed_list[i].this_settled)
								total_invoiced+=Number(payed_list[i].this_invoiced)
								total_cargo_weight+=Number(payed_list[i].tord_info.cargo_weight)
								this.total_num++
						}
					this.total = total
					this.total_cargo_weight = total_cargo_weight

					this.total = total.toFixed(2)
					this.total_service = total_service.toFixed(2)
					this.total_invoiced =total_invoiced.toFixed(2)
					}
				}else{
					for(var payed_num in this.payed_num_choosed){
						this.payed_num_choosed[payed_num].checked=false;
					}
					this.total_cargo_weight = 0
					this.total_num=0;
					this.total =0
					this.total_invoiced = 0
					this.total_service = 0
				}
			},
			get_payed_choosed(){//获取选中的值
				let choosed=[];
				for(var payed_num in this.payed_num_choosed){
					let item={
						...this.payed_num_choosed[payed_num]
					};
					if(item.checked){
						choosed.push(item);
					}
				}
				return choosed;
			},


			//支付审核通过
			tords_pay_check_pass(title){//批量

				//获取选中值
				let payed_choosed=this.get_payed_choosed();

				//没有选择支付记录
				if(payed_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择支付记录'
					})
					return;
				}

				//汇集需要到货的支付记录
				let payed_need_pass=[];
				for(var item of payed_choosed){
					if(item.pay_status==2){
						payed_need_pass.push(item);
					}
				}

				//已选择支付记录中没有需要通过的
				if(payed_need_pass.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择支付记录中没有需要通过的'
					})
					return;
				}
				var payed = []
				payed_need_pass.forEach(item=>{
					payed.push({
						 payed_num:item.payed_num
					})
				})
				this.$my.other.confirm({
					content:"您的操作是" + title + '请确认',
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_pay_plan_confirm_pay_list',
								list:payed,
							},
							callback:(data)=>{
								if(title == '通过支付'){
									var index = payed[0].payed_num
									this.payed_num_choosed[index].checked = false
								}
								//刷新本页
								this.get_page_data()
							},
						});
					},cancel:()=>{
						if(title == '通过支付'){
							var index = payed[0].payed_num
							this.payed_num_choosed[index].checked = false
						}
					}

				});
			},
			tords_pay_check_pass2(){//批量 扣款

				//获取选中值
				let payed_choosed=this.get_payed_choosed();

				//没有选择支付记录
				if(payed_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择支付记录'
					})
					return;
				}

				//汇集需要到货的支付记录
				let payed_need_pass=[];
				for(var item of payed_choosed){
					if(item.pay_status==2){
						payed_need_pass.push(item);
					}
				}

				//已选择支付记录中没有需要通过的
				if(payed_need_pass.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择支付记录中没有需要通过的'
					})
					return;
				}

				//置入本次要通过的支付记录
				this.pay_check_pass.need_pass_list=payed_need_pass

				//打开确认界面
				this.pay_check_pass.is_show++
			},
			tord_pay_check_pass(item){
				this.payed_num_choosed[item.payed_num].checked = true
				this.tords_pay_check_pass2('通过支付')//  不扣款

			},
			// tord_pay_check_pass(item){

			// 	//置入本次要通过的支付记录
			// 	this.pay_check_pass.need_pass_list=[item]

			// 	//打开确认界面
			// 	this.pay_check_pass.is_show++
			// },
			pay_check_passed(obj){

				//关闭页面
				this.pay_check_pass.is_show=0;

				//提醒
				this.$my.other.msg({
					str:'操作成功',
					type:'success'
				});

				//刷新本页
				this.get_page_data()
			},

			//驳回支付
			tords_pay_check_revoke(title){//批量

				//获取选中值
				let payed_choosed=this.get_payed_choosed();

				//没有选择支付记录
				if(payed_choosed.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'请选择支付记录'
					})
					return;
				}

				//汇集需要撤销的支付记录
				let payed_need_revoke=[];
				for(var item of payed_choosed){
					if(item.pay_status==2){
						payed_need_revoke.push({
							payed_num:item.payed_num,
						});
					}
				}

				//已选择支付记录中没有需要撤销的
				if(payed_need_revoke.length==0){
					this.$my.other.msg({
						type:'warning',
						str:'已选择支付记录中没有需要撤销的'
					})
					return;
				}

				//提示
				this.$my.other.confirm({
					content:"您的操作是" + title + '请确认',
					confirm:()=>{

						//提交
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_pay_plan_revoke_pay_list',
								list:payed_need_revoke,
							},
							callback:(data)=>{
							if(title == '撤销支付'){
								var index = payed_need_revoke[0].payed_num
								this.payed_num_choosed[index].checked = false
							}
								//刷新本页
								this.get_page_data()
							},
						});
					},cancel:()=>{
						if(title == '撤销支付'){
							var index = payed_need_revoke[0].payed_num
							this.payed_num_choosed[index].checked = false
						}
					}
				});
			},
			all_check_revoke(){

				//询问
				this.$my.other.confirm({
					content:"点击确定撤销搜索结果",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_pay_all_check_revoke_by_shipper',
								...this.form
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},
			//支付计划撤销
			pay_check_revoke_plan(item){
				this.payed_num_choosed[item.payed_num].checked = true
				this.tords_pay_check_revoke('撤销支付')
			},
			pay_check_revoke(item){

				//询问
				this.$my.other.confirm({
					content:"点击确定撤销此笔支付",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_pay_check_revoke_by_shipper',
								revoke_list:[
									{
										truck_tord_num:item.truck_tord_num,
										payed_num:item.payed_num,
									}
								]
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//撤销支付
			pay_check_refuse(item){//单个

				if(item.pay_status!=3){
					this.$my.other.msg({
						type:'warning',
						str:'已支付状态下可以撤销',
					});
					return
				}
				if(item.invoice_status!=1){
					this.$my.other.msg({
						type:'warning',
						str:'此支付记录已经进入支付流程,不能撤销支付',
					});
					return
				}
				if(item.settle_status_of_truck_owner==3&&item.this_settled_of_truck_owner>0){
					this.$my.other.msg({
						type:'warning',
						str:'此笔款项车队长部分已经结算,不能撤销支付',
					});
					return
				}
				if(item.settle_status_of_driver==3&&item.this_settled_of_driver>0){
					this.$my.other.msg({
						type:'warning',
						str:'此笔款项司机部分已经结算,不能撤销支付',
					});
					return
				}

				//询问
				this.$my.other.confirm({
					content:"点击确定撤销此笔支付",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_pay_check_refuse_by_shipper',
								list:[
									{
										truck_tord_num:item.truck_tord_num,
										payed_num:item.payed_num,
									}
								]
							},
							callback:(data)=>{

								//提醒
								this.$my.other.msg({
									str:'操作成功',
									type:'success'
								});

								//刷新本页
								this.get_page_data()
							}
						})
					}
				})
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					payed_num:'',//货源编号
					this_pay_shipper_ra_num:'',//流水编号
					truck_tord_num:'',//货源编号
					sub_user_tel:'',//子用户手机号
					sub_user_name:'',//子用户姓名
					truck_owner_tel:'',//收款人手机号
					truck_owner_name:'',//收款人姓名
					driver_tel:'',//司机手机号
					driver_name:'',//司机姓名
					this_pay_type:'',//本次支付类型(1:现金,2:燃油费,3:过路费)
					pay_status:'',//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
					invoice_status:'',//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
					settle_status:'',//结算给收款人的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
					mark:'',//备注
					truck_plate_num:"",//车牌号
					is_set_freight:'',//运费设置
					creat_time_start:'',
					creat_time_end:'',
					bl_name:'',//线路名称
					pay_mark:'',//支付类型二
					bind_bankcard_status:'',//绑卡状态
					cargo_name:'',
					tord_sub_user_name:'',//运单子户姓名
					tord_sub_user_tel:'',//运单子户手机号
				}
				this.date_value = ''
				this.get_page_data()
			},

			//搜索
			page_ser(){
			   if (this.date_value) {
					this.page.p =1
					//payed_time_start //payed_time_end
					this.form.creat_time_start = parseInt(this.date_value[0] / 1000);
					this.form.creat_time_end = parseInt(this.date_value[1] / 1000);
					this.get_page_data();
				}else{
					this.page.p =1
					this.form.creat_time_start = '',
					this.form.creat_time_end='',
					this.get_page_data();
				}
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){

				//置入页码
				this.page.p=p 

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;
				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_payed_list_by_shipper',
						// shipper_confirm:2,
						is_get_truck_owner_info:true,
						is_get_driver_info:true,
						is_get_tord_info:true,
						is_get_cost_info:true,
						...this.form,
						...this.page,
						pay_status:2,
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						// //总吨数
                        // this.list.total_cargo_weight = Number(data.total.total_cargo_weight).toFixed(2)
						// //总数
						this.list.total=data.max

						// this.list.total_freight_checking_truck_owner = data.total.total_freight_checking_truck_owner
						// this.list.total_freight_checking_driver = data.total.total_freight_checking_driver
						//运单列表
						let tord_list={};

						//
						let payed_num_choosed={};

						//预处理
						for(let item of data.list){
							if(item.driver_bank_list.num ==1){
								item.driver_bank_list_info = item.driver_bank_list.list[0]
							}
							if(item.truck_owner_bank_list.num ==1){
								item.truck_owner_bank_list_info = item.truck_owner_bank_list.list[0]
							}
							//下单时间
							item.tord_info.creat_time_text=this.$my.other.totime(item.tord_info.creat_time);

							//支付时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//本次支付类型(1:现金,2:燃油费,3:过路费)
							switch(item.this_pay_type){
								case '1':item.this_pay_type_text="现金";break;
								case '2':item.this_pay_type_text="燃油费";break;
								case '3':item.this_pay_type_text="过路费";break;
							}

							//支付状态(1:待审核,2:支付完成,3:支付失败/打回)
							switch(item.pay_status){
								case '1':item.pay_status_text="未支付";break;
								case '2':item.pay_status_text="支付中";break;
								case '3':
									item.pay_status_text=`支付成功 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '4':
									item.pay_status_text=`支付失败 ${this.$my.other.totime(item.payed_time)}`;
									break;
								case '5':
									item.pay_status_text=`已撤销 ${this.$my.other.totime(item.pay_refused_time)}`;
									break;
							}

							//开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
							switch(item.invoice_status){
								case '1':item.invoice_status_text="未申请";break;
								case '2':item.invoice_status_text="申请中";break;
								case '3':
									item.invoice_status_text=`开票成功 ${this.$my.other.totime(item.invoiced_time)}`;
									break;
								case '4':
									item.invoice_status_text=`开票失败 ${this.$my.other.totime(item.invoiced_time)}`;
									break;
							}
							var settled_time_of_truck_owner = ""
							if(item.settled_time_of_truck_owner !=0){
								settled_time_of_truck_owner = this.$my.other.totime(item.settled_time_of_truck_owner)
							}
							//结算给车队长的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(item.settle_status_of_truck_owner){
								case '1':item.settle_status_of_truck_owner_text="未申请";break;
								case '2':item.settle_status_of_truck_owner_text="待打款";break;
								case '3':
									item.settle_status_of_truck_owner_text=`打款成功 ${settled_time_of_truck_owner}`;
									break;
								case '4':
									item.settle_status_of_truck_owner_text=`打款失败 ${settled_time_of_truck_owner}`;
									break;
							}
							var settled_time_of_driver = ""
							if(item.settled_time_of_driver !=0){
								settled_time_of_driver = this.$my.other.totime(item.settled_time_of_driver)
							}
							//结算给司机的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
							switch(item.settle_status_of_driver){
								case '1':item.settle_status_of_driver_text="未申请";break;
								case '2':item.settle_status_of_driver_text="待打款";break;
								case '3':
									item.settle_status_of_driver_text=`打款成功 ${settled_time_of_driver}`;
									break;
								case '4':
									item.settle_status_of_driver_text=`打款失败 ${settled_time_of_truck_owner}`;
									break;
							}

							//本次支付
							item.this_payed_text=item.this_payed+"元"

							//计算本次结给承运人的全部金额
							item.this_settled=parseFloat(item.this_settled_of_truck_owner)+parseFloat(item.this_settled_of_driver)+parseFloat(item.this_settled_of_system)

							//服务费率模式: 显示服务费 显示结给司机的钱
							if(item.calc_mode==1){
								//服务费
								item.service_charge_text=(item.this_payed-item.this_settled).toFixed(2)+"元"
								//结给司机的钱
								item.this_settled_text=item.this_settled+"元"

							//固定金额模式
							}else if(item.calc_mode==2){

								//服务费
								item.service_charge_text="--"

								//结给司机的钱
								item.this_settled_text="--"
							}

							//装货联系人
							if(item.tord_info.case_link_man){
								item.tord_info.case_link_man_text=item.tord_info.case_link_man;
							}else item.tord_info.case_link_man_text="无";

							//装货联系电话
							if(item.tord_info.case_link_tel){
								item.tord_info.case_link_tel_text=item.tord_info.case_link_tel;
							}else item.tord_info.case_link_tel_text="无";

							//卸货联系人
							if(item.tord_info.aim_link_man){
								item.tord_info.aim_link_man_text=item.tord_info.aim_link_man;
							}else item.tord_info.aim_link_man_text="无";

							//卸货联系电话
							if(item.tord_info.aim_link_tel){
								item.tord_info.aim_link_tel_text=item.tord_info.aim_link_tel;
							}else item.tord_info.aim_link_tel_text="无";

							/* 装货地其他数据(json) */
							item.tord_info.case_other_obj=JSON.parse(item.tord_info.case_other);

							//装货时间
							if(!item.tord_info.case_other_obj.case_time_start && item.tord_info.case_other_obj.case_time_end){//只有止点

								item.tord_info.case_other_obj.case_date=this.$my.other.todate(item.tord_info.case_other_obj.case_time_end)+"以前";

							}else if(item.tord_info.case_other_obj.case_time_start && !item.tord_info.case_other_obj.case_time_end){//只有起点

								item.tord_info.case_other_obj.case_date=this.$my.other.todate(item.tord_info.case_other_obj.case_time_start)+"以后";

							}else if(item.tord_info.case_other_obj.case_time_start && item.tord_info.case_other_obj.case_time_end){//都有

								item.tord_info.case_other_obj.case_date=this.$my.other.todate(item.tord_info.case_other_obj.case_time_start)+" ~ "+this.$my.other.todate(item.tord_info.case_other_obj.case_time_end);

							}else if(!item.tord_info.case_other_obj.case_time_start && !item.tord_info.case_other_obj.case_time_end){//都没有

								item.tord_info.case_other_obj.case_date="未设置";
							}

							/* 卸货地其他数据(json) */
							item.tord_info.aim_other_obj=JSON.parse(item.tord_info.aim_other);

							//卸货时间
							if(!item.tord_info.aim_other_obj.aim_time_start && item.tord_info.aim_other_obj.aim_time_end){//只有止点

								item.tord_info.aim_other_obj.aim_date=this.$my.other.todate(item.tord_info.aim_other_obj.aim_time_end)+"以前";

							}else if(item.tord_info.aim_other_obj.aim_time_start && !item.tord_info.aim_other_obj.aim_time_end){//只有起点

								item.tord_info.aim_other_obj.aim_date=this.$my.other.todate(item.tord_info.aim_other_obj.aim_time_start)+"以后";

							}else if(item.tord_info.aim_other_obj.aim_time_start && item.tord_info.aim_other_obj.aim_time_end){//都有

								item.tord_info.aim_other_obj.aim_date=this.$my.other.todate(item.tord_info.aim_other_obj.aim_time_start)+" ~ "+this.$my.other.todate(item.tord_info.aim_other_obj.aim_time_end);

							}else if(!item.tord_info.aim_other_obj.aim_time_start && !item.tord_info.aim_other_obj.aim_time_end){//都没有

								item.tord_info.aim_other_obj.aim_date="未设置";
							}

							//运单状态
							switch(item.tord_info.status){
								case '1':item.tord_info.status_text="待发车";break;
								case '2':item.tord_info.status_text="运输中";break;
								case '3':item.tord_info.status_text="已到货";break;
							}

							//结算方式
							switch(item.tord_info.settlement_type){
								case '1':item.tord_info.settlement_type_text="按吨数";break;
								case '2':item.tord_info.settlement_type_text="按方数";break;
								case '3':item.tord_info.settlement_type_text="按趟";break;
							}

							//是否回程
							switch(item.tord_info.is_need_return){
								case '1':item.tord_info.is_need_return_text="需要回程";break;
								case '2':item.tord_info.is_need_return_text="不需要回程";break;
							}

							//汇总运单数据
							if(!tord_list[item.tord_info.id]){
								tord_list[item.tord_info.id]=JSON.parse(JSON.stringify(item.tord_info))
								tord_list[item.tord_info.id].cost_info=JSON.parse(JSON.stringify(item.cost_info))
								tord_list[item.tord_info.id].driver_info=JSON.parse(JSON.stringify(item.driver_info))
								tord_list[item.tord_info.id].truck_owner_info=JSON.parse(JSON.stringify(item.truck_owner_info))
								tord_list[item.tord_info.id].sub_user_info=JSON.parse(JSON.stringify(item.tord_info.sub_user_info))
								tord_list[item.tord_info.id].payed_list=[];
							}

							//缓存运单id
							var tord_id=item.tord_info.id
							//导出数据置入
							item.aim_addr = item.tord_info.aim_prov + item.tord_info.aim_city + item.tord_info.aim_county
                            item.case_addr = item.tord_info.case_prov + item.tord_info.case_city + item.tord_info.case_county
                            item.driver_name = item.driver_info.name
                            item.driver_tel = item.driver_info.tel
                            item.truck_plate_num = item.tord_info.truck_plate_num
							item.sub_user_info_name = item.sub_user_info.name
							item.sub_user_info_tel = item.sub_user_info.tel
							//删除一些数据
							// delete(item.tord_info);
							delete(item.cost_info);
							delete(item.driver_info);
							delete(item.shipper_info);
							delete(item.truck_owner_info);
							delete(item.truck_info);
							tord_list[tord_id].pay_time_text = item.creat_time_text
							//置入支付记录数据
							tord_list[tord_id].payed_list.push(JSON.parse(JSON.stringify(item)))
							//置入
							payed_num_choosed[item.payed_num]={
								checked:false,
								payed_num:item.payed_num,
								truck_tord_num:item.truck_tord_num,
								this_payed:item.this_payed,
								pay_status:item.pay_status,
								invoice_status:item.invoice_status,
								settle_status:item.settle_status,
								tord_payed_status:tord_list[tord_id].payed_status,
								total_invoiced:item.this_invoiced,
								total_service:item.service_charge_text=(item.this_payed-item.this_settled).toFixed(2),
								total:item.this_settled,
								exlce_pay:JSON.parse(JSON.stringify(item)),
								cargo_weight:item.tord_info.cargo_weight
							}
						}
						this.list.total_freight_checking = data.total.total_freight_checking
						this.list.total_service_checking = data.total.total_service_checking
						this.list.total_money = Number(data.total.total_freight_checking + data.total.total_service_checking).toFixed(2)
						//渲染
						this.list.data=Object.values(tord_list)
						//缓存支付编号数据
						this.payed_num_choosed=payed_num_choosed;
						//请求总数
						this.$my.net.req({
							take_over_control:1,
							data: {
								mod:'truck_tord_real_time',
								ctr:'tord_payed_list_by_shipper',
								is_get_truck_owner_info:true,
								is_get_driver_info:true,
								is_get_tord_info:true,
								is_get_cost_info:true,
								...this.form,
								...this.page,
								pay_status:2,
								only_total:1
							},callback:(res)=>{
								this.list.total_freight_checking_driver=res.msg.total.total_freight_checking_driver

								this.list.total_invoice=res.msg.total.total_invoice

								this.list.total_freight_checking_truck_owner=res.msg.total.total_freight_checking_truck_owner
								this.list.total_cargo_weight = Number(res.msg.total.total_cargo_weight).toFixed(2)
								// var  service_charge_text = res.msg.total.total_invoice - (Number(res.msg.total.total_driver) + Number(res.msg.total.total_truck_owner))
								// this.list.service_charge_text =service_charge_text.toFixed(2)
							}
						})
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	.table {
		background-color: #fff;
		color: #666;
		text-align: left;
		height:calc(100% - 190px);
		font-size: 12px;
		border-bottom: 1px solid #ccc;
		.thead {
			border-bottom: 1px solid #ccc;
		}

		.tbody {
			height: calc(100% - 41px);
			overflow-y: auto;
			.tr {
				padding: 10px;
				border: 1px solid #eee;
				margin:10px;
				.tord_info{
					display: flex;
					padding:4px;
					background-color: #eee;
					justify-content: space-between;
					.item{
						margin: 3px;
					}
				}
				.payed_list{
					margin-top:6px;
					.ptr{
						display: flex;
						.ptd{
							padding:3px;
							margin: 3px;
						}
					}
				}
			}
		}
	}

	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
	.total {
		text-align: right;
		font-size: 12px;
		color: #606266;
		span {
			margin-left: 20px;
		}
	}
</style>